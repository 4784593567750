import 'core-js/stable';
import Vue from 'vue';
import 'trix';
import 'trix/dist/trix.css';
import CoreuiVue from '@coreui/vue';
import Notifications from 'vue-notification';
import FlowChart from "flowchart-vue";
import Api from './plugins/api';
import App from './App';
import Utils from './plugins/ortutils';
import Websocket from './plugins/websocket';
import ScreenSize from './plugins/screensize';
import router from './router/index';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store';
import vuexI18n from 'vuex-i18n';
import Locales from './locale/octopus-locales.js';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.prototype.$apiAddress = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_DEVSERVER : process.env.VUE_APP_PRODUCTSERVER;
Vue.prototype.$frontServerAddress = process.env.NODE_ENV !== 'production' ? process.env.VUE_APP_LOCAL_FRONTSERVER : process.env.VUE_APP_PRODUCT_FRONTSERVER;
Vue.prototype.$defaultNumber = 63;
Vue.prototype.$adminVersion = '0.7.8';
Vue.prototype.$authKey = 'pKju76Thg';
Vue.prototype.$eventBus = new Vue();
Vue.config.performance = true;

Vue.use(Utils);
Vue.use(Api);
Vue.use(Websocket);
Vue.use(ScreenSize);
Vue.use(CoreuiVue);
Vue.use(FlowChart);
Vue.use(Notifications);
Vue.use(vuexI18n.plugin, store, {
	moduleName: 'i18n',
	onTranslationNotFound (locale, key) {
		console.warn(`i18n :: Key '${key}' not found for locale '${locale}'`);
	}
});
Vue.i18n.add('en', Locales.en);
Vue.i18n.add('hu', Locales.hu);
const locale = store.state.currentLang ?? 'hu';
Vue.i18n.set(locale);

Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_MAP_API_CODE,
		libraries: ['places', 'visualization'],
		installComponents: true
		// v: 'OPTIONAL VERSION NUMBER',
	}
});

/** Globális hibakezelő beállítása - csak fejlesztés közben érdekes, ha valahol hiba van
Vue.config.errorHandler = function (err, vm, info) {
    // Komponens neve vagy egyedi azonosító
    const componentName = vm.$options.name || vm.$options._componentTag || 'Névtelen komponens';
    // Komponens helye (fájlnév és útvonal, ha elérhető)
    const componentFile = vm.$options.__file || 'Nem elérhető';
    // Hibák részletes kiíratása a konzolba
    console.error(`Hiba a renderelés során: ${info}`);
    console.error(`Hibaüzenet: ${err.message}`);
    console.error(`Komponens: ${componentName}`);
    console.error(`Fájl: ${componentFile}`);
    console.error('Részletek:', err);
};
*/

try {
	new Vue({
		el: '#app',
		store,
		router,
		//CIcon component documentation: https://coreui.io/vue/docs/components/icon
		icons,
		template: '<App/>',
		components: {
			App
		}
	});
}
catch(e) {
	console.error(e);
}
